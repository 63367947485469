.introTwo_body {
  display: flex;
  height: 100vh;
  width: 100%;
  color: #262d40;
  background-color: transparent;
}
.intro_two {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  height: 70%;
  margin-left: 10%;
  margin-right: 10%;
  place-self: flex-end;
}
.intro_two_text {
  max-width: 250px;
  margin-left: 50px;
}
.intro_two_description {
  font-size: medium;
  font-style: italic;
  opacity: 0.7;
}
.intro_title_2 {
  margin: 0px 30px;
  font-weight: 1000;
}
.intro_subtitle_2 {
  margin: 10px 30px;
  max-width: 600px;
  font-weight: 100;
}

@media only screen and (max-width: 768px) {
  .intro_two {
    grid-template-columns: 1fr;
    margin: auto;
    text-align: center;
  }
  .intro_two_text {
    max-width: none;
    margin: auto 25px;
  }
  .introTwo_body {
    display: block;
    overflow-y: auto;
  }
}
