.first_background {
  position: absolute;
  z-index: -1;
  background-color: transparent;
}
.first_body {
  display: flex;
  height: 100vh;
  width: 100%;
  color: #262d40;
  background-color: transparent;
}
.first_header {
  position: absolute;
  display: flex;
  margin: 30px;
  margin-left: 70px;
}
.first_content {
  display: grid;
  place-self: flex-end;
  grid-template-columns: 1fr 1fr;
}
.first_subcontent {
  margin-left: 80px;
  margin-bottom: 30px;
}
.bancame_index {
  font-size: 6em;
  color: #f44c7f;
}
.title {
  align-self: center;
  margin: 0;
  margin-left: 30px;
}
.services {
  color: white;
  background-color: #262d40;
  padding: 15px 70px;
  border-radius: 50px;
}
.chartPercentage {
  margin-top: -115px;
  margin-bottom: 115px;
  margin-left: 175px;
}
.pageNumber01 {
  color: white;
  display: flex;
  background-color: #35aac5;
  border-radius: 50px;
  z-index: 2;
  margin: 10px;
  place-self: flex-end;
}

.subtitle_1 {
  margin: 0;
  margin-left: 90px;
  font-size: 30px;
}

.description {
  max-width: 290px;
  margin-left: 100px;
  text-align: justify;
}
.img_1_container {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.img_1 {
  float: left;
  place-self: flex-end;
  width: 550px;
  height: 400px;
}

@media only screen and (min-width: 1500px) {
  .first_subcontent {
    margin-left: 300px;
  }
  .img_1 {
    width: 1000px;
    height: 570px;
  }
  .bancame_index {
    font-size: 10em;
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 768px) {
  .img_1_container {
    position: relative;
    width: auto;
    height: auto;
    margin: auto;
  }
  .first_subcontent {
    margin: auto;
  }
  .img_1 {
    height: 200px;
    width: 300px;
    margin: auto;
  }
  .title {
    margin: 15px auto;
  }
  .first_header {
    display: block;
    position: relative;
    text-align: center;
    margin: 30px;
  }
  .first_content {
    grid-template-columns: 1fr;
    text-align: center;
    place-items: center;
    margin: 50px auto;
    margin-bottom: 100px;
  }
  .first_body {
    display: block;
    overflow-y: auto;
  }

  .subtitle_1 {
    margin: 20px auto;
    max-width: none;
  }
  .description {
    margin: 20px;
    max-width: none;
  }
}
