.second_background {
  position: absolute;
  z-index: -1;
  background-color: transparent;
}
.second_background_mobile {
  position: absolute;
  z-index: -1;
  background-color: transparent;
}

.second_body {
  display: flex;
  height: 100vh;
  width: 100%;
  color: #262d40;
  background-color: transparent;
}

.second_header {
  position: absolute;
  display: flex;
  margin: 30px;
  margin-left: 70px;
}
.second_content {
  display: grid;
  grid-template-columns: 1fr;
  place-self: flex-end;
  text-align: center;
}

.title_2 {
  max-width: 700px;
  margin-left: 30px;
}
.title_21 {
  font-weight: 100;
  font-size: 18px;
}
.img_2_container {
  position: absolute;
  width: 100%;
}
.img_2 {
  float: right;
  width: 400px;
  height: 400px;
}

.pageNumber02 {
  color: white;
  display: flex;
  background-color: #164550;
  border-radius: 50px;
  z-index: 2;
  margin: 10px;
  place-self: flex-end;
}
.second_subContent {
  margin: 30px;
  margin-bottom: 10px;
  max-width: 320px;
}
.info_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #d7eff4;
  border-radius: 30px;
  height: 300px;
  width: 1000px;
  margin: 150px;
  box-shadow: 0 16px 16px 0 rgba(38, 45, 64, 0.5);
  transition: 1s;
}
.info_container:hover {
  box-shadow: 0 16px 16px 0 rgba(38, 45, 64, 0.8);
}
.info_subContainer {
  text-align: left;
  place-self: center;
  margin-right: 20px;
  transition: 2s;
}
.info_title {
  display: grid;
  grid-template-columns: 20fr 1fr;
  align-items: center;
}
.description_2 {
  margin: 30px auto;
  margin-right: 50px;
}
.chart_container {
  height: 300px;
  width: 300px;
  place-self: center;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: small;
}

.tooltip .tooltiptext {
  opacity: 0;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -50px;
  transition: 1s;
}
.tooltip .tooltiptext-right {
  opacity: 0;
  width: 140px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -20px;
  left: 130%;
}

.tooltip:hover .tooltiptext {
  opacity: 0.7;
}
.tooltip:hover .tooltiptext-right {
  opacity: 0.7;
}
.tooltip .tooltiptext-right::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.material-icons {
  font-size: 35px;
  transition: 1s;
}
.data_buttons {
  text-align: right;
  margin-top: 90px;
}
.badge {
  position: relative;
  top: -10px;
  left: -10px;
  background-color: white;
  padding: 1px 4px;
  border-radius: 50%;
  border: 3px solid silver;
}
.upper_arrow {
  margin-right: 85px;
  text-align: right;
}
.select_description {
  display: flex;
  font-size: 11px;
  font-style: italic;
  text-align: center;
  float: right;
  max-width: 150px;
  margin-right: 15px;
}
@media only screen and (min-width: 1600px) {
  .second_subContent {
    font-size: 1.3em;
    max-width: 400px;
  }
  .chartPercentage_2 {
    margin-top: -120px;
    margin-bottom: 120px;
  }
  .title_2 {
    max-width: 1000px;
    font-size: 1.5em;
  }
  .title_21 {
    font-size: 25px;
  }
  .img_2 {
    width: 550px;
    height: 550px;
  }
}

@media only screen and (max-width: 768px) {
  .info_container {
    grid-template-columns: 1fr;
    margin: 10px;
    width: auto;
    height: auto;
  }
  .info_subContainer {
    text-align: center;
    margin-right: auto;
  }
  .info_title {
    grid-template-columns: 1fr;
  }
  .second_background {
    display: none;
  }
  .second_body {
    position: relative;
    display: block;
    overflow-y: auto;
    text-align: center;
    margin-bottom: 40px;
  }
  .second_header {
    position: relative;
    display: block;
    text-align: center;
    margin: 30px;
  }
  .img_2_container {
    position: relative;
    display: block;
  }
  .img_2 {
    height: 250px;
    width: 250px;
    float: none;
  }
  .second_content {
    grid-template-columns: 1fr;
    margin: auto;
    max-width: 350px;
    margin-bottom: 100px;
  }
  .title_2 {
    margin-left: 0;
    font-size: 17px;
  }
  .subtitle_2 {
    max-width: none;
    margin: auto;
  }
  .description_2 {
    margin: 30px auto;
  }
  .data_buttons {
    text-align: center;
    padding-left: 20px;
  }
  .upper_arrow {
    margin: auto;
    text-align: center;
  }
  .select_description {
    margin: auto;
    float: none;
  }
  .tooltip .tooltiptext-right {
    width: 115px;
    top: -25px;
  }
}
