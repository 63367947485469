.seventh_background {
  position: absolute;
  z-index: -1;
  background-color: transparent;
}
.seventh_body {
  display: flex;
  height: 100vh;
  width: 100%;
  color: #262d40;
  background-color: transparent;
}
.seventh_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-self: center;
}
.seventh_subcontent {
  color: white;
  display: flex;
  align-items: center;
}

.seventh_header {
  position: absolute;
  display: flex;
  margin: 30px;
  margin-left: 70px;
}
.img_6_container {
  position: absolute;
  width: 100%;
}
.img_7 {
  float: right;
  height: 570px;
}

.pageNumber03 {
  color: white;
  display: flex;
  background-color: #164550;
  border-radius: 50px;
  z-index: 2;
  margin: 10px;
  place-self: flex-end;
}
.mail_7 {
  color: #35aac5;
}
@media only screen and (min-width: 1600px) {
  .img_7 {
    height: 700px;
  }
  .seventh_subcontent {
    font-size: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .seventh_header {
    position: relative;
    display: block;
    text-align: center;
    margin: 30px;
  }
  .seventh_body {
    position: relative;
    display: block;
    overflow-y: auto;
    text-align: center;
  }
  .seventh_content {
    grid-template-columns: 1fr;
    margin: auto;
    padding: 30px;
  }
  .img_6_container {
    position: relative;
    display: block;
  }
  .img_7 {
    height: 250px;
    width: 250px;
    float: none;
    margin: auto;
  }
  .seventh_subcontent {
    justify-self: center;
  }
  .mail_7 {
    font-size: 21px;
  }
}
