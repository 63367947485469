body {
  font: 20px/1.2143 "Rubik", sans-serif;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #d7eff4;
}

::-webkit-scrollbar {
  width: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  border-radius: 8px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  margin: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #262d40;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1 {
  margin: 0;
}
h2 {
  margin: 0;
}

.pageBody {
  height: 100%;
  background-color: transparent;
}
.pageButtons {
  position: absolute;
  display: flex;
  place-self: flex-end;
  width: 100%;
  justify-content: right;
  height: 100%;
}
.pageText {
  padding: 10px;
}
.nextButton {
  background-color: #fccfdd;
  padding: 10px;
  border-radius: 60px;
  transition: 1s;
}

.stopButton {
  background-color: #fccfdd;
  padding: 10px;
  border-radius: 60px;
}
.fa {
  color: #7f8eb3;
  transition: 1s;
}

.nextButton:hover {
  background-color: #262d40;
  cursor: pointer;
}
.fa:hover {
  color: #35aac5;
}
.demo {
  color: #fccfdd;
}
.CRlogIn:hover {
  cursor: pointer;
  color: #35aac5;
}
.GRlogIn:hover {
  cursor: pointer;
  color: white;
}

.companyReportbackground {
  position: absolute;
  z-index: -1;
  background-color: #262d40;
}

.generateReportbackground {
  position: absolute;
  z-index: -1;
  background-color: #35aac5;
}

.loader {
  margin: 15px auto;
  border: 8px solid #fccfdd;
  border-radius: 50%;
  border-top: 8px solid #f44c7f;
  /* border-right: 10px solid #f44c7f; */
  /* border-bottom: 10px solid #262d40;
  border-left: 10px solid #262d40; */
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px) {
  .pageButtons {
    justify-content: center;
  }
}
