.intro_one {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 79%;
  margin-left: 7%;
  margin-right: 7%;
  place-self: flex-end;
}
.intro_one_text {
  max-width: 420px;
}
.intro_two_description {
  font-size: medium;
  font-style: italic;
  opacity: 0.7;
  text-align: justify;
}
.intro_title_2 {
  margin: 0px 30px;
}
.intro_subtitle_2 {
  margin: 10px 30px;
  max-width: 600px;
  font-weight: 100;
}

@media only screen and (max-width: 768px) {
  .intro_one {
    grid-template-columns: 1fr;
    margin: auto;
    
  }
  .intro_one_text{
    margin: 20px;
  }
}
