.auth_background {
  position: absolute;
  z-index: -1;
}
.auth_body {
  display: flex;
  height: 100vh;
  width: 100%;
}
.auth_content {
  margin: auto;
  text-align: center;
  color: #164550;
  transition: 0.5s;
}
.hr_buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.HRImage {
  border-radius: 40px;
  margin: 20px;
  border: white solid;
}
.HRImage:hover {
  cursor: pointer;
  border: black solid;
}
.talana {
  background-color: white;
  border-radius: 40px;
  padding: 30px 25px;
  padding-bottom: 40px;
  width: 350px;
  height: 60px;
}
.buk {
  color: transparent;
}
.buk:hover {
  color: #164550;
}
.buk_1 {
  background-color: #6783b2;
  border-radius: 40px;
  padding: 5px 25px;
  width: 350px;
  height: 120px;
}
.buk_1:hover {
  opacity: 0.2;
}
.centered {
  font-size: 50px;
  margin-top: -120px;
  padding: 0;
  transition: 1.5s;
}

.HRlogIn:hover {
  cursor: pointer;
  color: #262d40;
}
.auth_buttons {
  border: 1px solid;
  margin: auto;
  border-radius: 10px;
  padding: 30px;
}
.auth_inputs {
  display: block;
  height: 30px;
  margin: 15px auto;
  font-weight: bold;
  font-size: 18px;
  padding: 5px;
  border-radius: 10px;
}
.fade-enter {
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 700ms;
}

@media only screen and (max-width: 768px) {
  .hr_buttons {
    grid-template-columns: 1fr;
  }
  .talana {
    width: 200px;
  }
  .buk_1 {
    width: 200px;
  }
}
