.fourth_background {
  position: absolute;
  z-index: -1;
  background-color: transparent;
}
.ellipse_4 {
  position: absolute;
  background-color: transparent;
  z-index: -1;
}

.fourth_body {
  display: flex;
  height: 100vh;
  width: 100%;
  color: #262d40;
  background-color: transparent;
}

.fourth_header {
  position: absolute;
  display: flex;
  margin: 30px;
  margin-left: 70px;
}
.fourth_content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-self: flex-end;
  margin-right: 400px;
  text-align: center;
}
.fourth_subContent {
  margin: 10px;
  margin-bottom: 10px;
  max-width: 320px;
}

.title_4 {
  max-width: 700px;
  margin-left: 50px;
}

.img_4_container {
  position: absolute;
  width: 100%;
}
.img_4 {
  float: right;
  width: 400px;
  height: 400px;
  margin-top: 180px;
}

.chartPercentage_4 {
  margin-top: -90px;
  margin-bottom: 90px;
  margin-left: 20px;
}

.pageNumber02 {
  color: white;
  display: flex;
  background-color: #164550;
  border-radius: 50px;
  z-index: 2;
  margin: 10px;
  place-self: flex-end;
}
@media only screen and (min-width: 1600px) {
  .fourth_subContent {
    font-size: 1.2em;
    max-width: 400px;
  }
  .chartPercentage_4 {
    margin-top: -120px;
    margin-bottom: 120px;
  }
  .title_4 {
    max-width: 1000px;
    font-size: 3em;
  }
  .img_4 {
    width: 500px;
    height: 500px;
    margin-right: 0px;
    margin-top: 300px;
  }
}

@media only screen and (max-width: 768px) {
  .ellipse_4 {
    display: none;
  }
  .fourth_body {
    position: relative;
    display: block;
    overflow-y: auto;
  }
  .fourth_header {
    position: relative;
    display: block;
    text-align: center;
    margin: 30px;
  }
  .img_4_container {
    position: relative;
    display: block;
    text-align: center;
  }
  .img_4 {
    height: 230px;
    width: auto;
    float: none;
    margin: auto;
    margin-left: 40px;
  }
  .fourth_content {
    grid-template-columns: 1fr;
    margin: auto;
    max-width: 320px;
    margin-bottom: 100px;
  }
  .title_4 {
    margin: auto;
    font-size: 25px;
  }
  .subtitle_4 {
    margin: auto 10%;
  }
  .chartPercentage_4 {
    margin-top: -90px;
    margin-bottom: 90px;
    margin-left: 20px;
  }
  .description_4 {
    margin: 30px auto;
  }
}
