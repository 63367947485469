.third_background {
  position: absolute;
  z-index: -1;
  background-color: transparent;
}
.third_body {
  display: flex;
  height: 100vh;
  width: 100%;
  color: #262d40;
  background-color: transparent;
}

.ellipse_3 {
  position: absolute;
  background-color: transparent;
  z-index: -3;
}

.third_header {
  position: absolute;
  display: flex;
  margin: 30px;
  margin-left: 70px;
}
.title_3 {
  margin-left: 50px;
  max-width: 900px;
}
.img_3_container {
  position: absolute;
  width: 100%;
  z-index: -2;
}
.img_3 {
  float: right;
  width: 400px;
  height: 400px;
  margin-right: 10px;
  margin-top: 180px;
}

.pageNumber03 {
  color: white;
  display: flex;
  background-color: #164550;
  border-radius: 50px;
  z-index: 2;
  margin: 10px;
  place-self: flex-end;
}
@media only screen and (min-width: 1600px) {
  .title_3 {
    max-width: 1000px;
    font-size: 3em;
  }
  .img_3 {
    width: 500px;
    height: 500px;
    margin-right: 0px;
    margin-top: 300px;
  }
}

@media only screen and (max-width: 768px) {
  .third_header {
    position: relative;
    display: block;
    text-align: center;
    margin: 30px;
  }
  .third_body {
    position: relative;
    display: block;
    overflow-y: auto;
    text-align: center;
    margin-bottom: 40px;
  }

  .img_3_container {
    position: relative;
    display: block;
    margin: auto;
  }
  .img_3 {
    height: 250px;
    width: 200px;
    float: none;
    margin: auto;
  }
  .title_3 {
    margin: 20px 0px;
    font-size: x-large;
  }
  .ellipse_3 {
    display: none;
  }
}
