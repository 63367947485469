.fifth_background {
  position: absolute;
  z-index: -1;
  background-color: transparent;
}

.ellipse_5 {
  position: absolute;
  background-color: transparent;
  z-index: -1;
}
.fifth_body {
  display: flex;
  height: 100vh;
  width: 100%;
  color: #262d40;
  background-color: transparent;
}
.fifth_content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  text-align: center;
  width: 100%;
  height: 65%;
  place-self: flex-end;
  /* margin-left: 100px; */
}

.chartPercentage_5 {
  margin-top: -85px;
  margin-bottom: 85px;
  margin-left: 20px;
}

.fifth_header {
  position: absolute;
  display: flex;
  margin: 30px;
  margin-left: 70px;
}
.title_5 {
  margin-left: 50px;
  max-width: 900px;
}

.img_5 {
  opacity: 0.75;
  z-index: 1;
  display: block;
}
.img_5:hover {
  opacity: 1;
  cursor: pointer;
}
.img_detail_1 {
  width: 180px;
  height: 180px;
}
.img_detail_2 {
  width: 300px;
  height: 250px;
}

.pageNumber03 {
  color: white;
  display: flex;
  background-color: #164550;
  border-radius: 50px;
  z-index: 2;
  margin: 10px;
  place-self: flex-end;
}
.detail_content_body {
  display: grid;
  grid-template-columns: 1fr 2fr;
  text-align: center;
  width: 100%;
  height: 80%;
  place-self: flex-end;
  /* margin-left: 100px; */
}

.detail_subcontent {
  max-width: 280px;
  margin: 10px;
  border-radius: 20px;
  border: 1px rgba(0, 0, 0, 0.2) solid;
}
.detail_select {
  background-color: #d7eff4;
  border-radius: 20px;
  padding: 30px;
  margin: 30px;
  margin-top: 5px;
  margin-right: 0;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: dotted black 2px;
  box-shadow: 0px 10px 10px 10px rgba(38, 45, 64, 0.5);
}
.detail_content {
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr; */
  overflow-y: auto;
  max-height: 470px;
  padding: 20px;
  margin: 30px;
  margin-top: 5px;
  margin-left: 0;
  background-color: #d7eff4;
  border-radius: 20px;
  border-top-left-radius: 0px;
  box-shadow: 10px 10px 10px 10px rgba(38, 45, 64, 0.5);
}
.detail_content_charts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 10px;
  margin-bottom: 20px;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 5px 5px 5px 0px rgba(38, 45, 64, 0.5);
}
.egresos {
  background-color: #35aac5;
}
.gastos {
  background-color: #7f8eb3;
}
.deudas {
  background-color: #f44c7f;
}
.react_select {
  max-width: 250px;
  margin: auto;
}
.recharts-wrapper {
  margin: auto;
}
@media only screen and (min-width: 1600px) {
  .detail_content {
    max-height: 600px;
  }
  .title_5 {
    font-size: 2em;
  }
}

@media only screen and (max-width: 768px) {
  .detail_select {
    margin: 5%;
    border-radius: 20px;
  }
  .detail_content {
    margin: 5%;
    border-radius: 20px;
  }

  .detail_content_body {
    grid-template-columns: 1fr;
  }
  .detail_content_charts {
    grid-template-columns: 1fr;
    margin: auto;
    margin-bottom: 70px;
  }
  .detail_content_charts {
    padding: 0;
    padding-top: 30px;
  }
  .ellipse_5 {
    display: none;
  }
  .fifth_header {
    position: relative;
    display: block;
    text-align: center;
    margin: 30px;
  }
  .fifth_body {
    position: relative;
    display: block;
    overflow-y: auto;
    text-align: center;
  }

  .img_5 {
    opacity: 1;
  }
  .title_5 {
    margin: 20px auto;
    max-width: none;
    font-size: x-large;
  }

  .subtitle_5 {
    max-width: none;
    margin: 20px auto;
  }
  .description_5 {
    margin: auto;
  }
  .chartPercentage_5 {
    margin-top: -90px;
    margin-bottom: 90px;
    margin-left: 25px;
  }
  .img_detail_1 {
    display: none;
  }
  .img_detail_2 {
    width: 300px;
    height: 300px;
  }
  .detail_subcontent {
    margin: 5px auto;
  }
  .fifth_content {
    grid-template-columns: 1fr;
  }
  .detail_content {
    grid-template-columns: 1fr;
  }
}
